<template>
  <div id="product">
    <el-dialog
      :title="airFormTitle"
      width="600px"
      :visible.sync="airDialogVisible"
      :close-on-click-modal="false"
      @close="airDialogClose"
      ><el-form
        ref="airFormRef"
        :model="airForm"
        :rules="productFormRules2"
        label-position="right"
        label-width="180px"
      >
        <el-form-item label="文件名称" prop="fileName">
          <el-input
            v-model="airForm.fileName"
            placeholder="请输入文件名称"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="文件编号" prop="fileNo">
          <el-input
            v-model="airForm.fileNo"
            placeholder="请输入文件编号"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="申请更改部门" prop="applyChangeDept">
          <el-input
            v-model="airForm.applyChangeDept"
            placeholder="请输入申请更改部门"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="更改原因" prop="changeReason">
          <el-input
            v-model="airForm.changeReason"
            placeholder="请输入更改原因"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="申请人" prop="applicant">
          <el-input
            v-model="airForm.applicant"
            placeholder="请输入申请人"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="申请日期" prop="applicationDate">
          <el-date-picker
            v-model="airForm.applicationDate"
            type="datetime"
            placeholder="选择申请日期"
            value-format="yyyy-MM-dd hh:mm:ss"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="变更内容简述" prop="changeBrief">
          <el-input
            v-model="airForm.changeBrief"
            placeholder="请输入变更内容简述"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="审核部门意见" prop="reviewDeptOpinion">
          <el-input
            v-model="airForm.reviewDeptOpinion"
            placeholder="请输入审核部门意见"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>

        <el-form-item label="审核人" prop="reviewer">
          <el-input
            v-model="airForm.reviewer"
            placeholder="请输入审核人"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="审核日期" prop="reviewDate">
          <el-date-picker
            v-model="airForm.reviewDate"
            type="datetime"
            placeholder="选择审核日期"
            value-format="yyyy-MM-dd hh:mm:ss"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="批准部门意见" prop="approvalDeptOpinion">
          <el-input
            v-model="airForm.approvalDeptOpinion"
            placeholder="请输入批准部门意见"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="批准人" prop="approver">
          <el-input
            v-model="airForm.approver"
            placeholder="请输入批准人"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="批准时间" prop="approveDate">
          <el-date-picker
            v-model="airForm.approveDate"
            type="datetime"
            placeholder="选择批准时间"
            value-format="yyyy-MM-dd hh:mm:ss"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="productFormSubmit"> 确 定 </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="文件名称">
        <el-input
          v-model="searchForm.fileName"
          placeholder="请输入文件名称"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="waterList.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="fileName" label="文件记录名称" />
      <el-table-column prop="applicant" label="申请人" />
      <el-table-column prop="applicationDate" label="申请日期" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="waterList.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  getChangeList,
  deleteChangeList,
  addChangeList,
  getChangeDetail,
  updateChangeDetail,
} from "@/api/humanManagement/humanManagement.js";

export default {
  data() {
    return {
      airDialogVisible: false,
      innerVisible: false,
      airFormTitle: "",
      airForm: {},
      productPage: {
        list: [],
        total: 0,
      },
      waterList: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      typeList: [],
      typeMap: [],
      tableData: [],
      handleType: "",
      handleDetailType: "",
      runningTime: [],
      airDetailForm: {},
      productFormRules: {
        disinfectionObject: [
          {
            required: true,
            message: "时间名称不能为空",
            trigger: ["change"],
          },
        ],
      },
      productFormRules2: {
        fileName: [
          {
            required: true,
            message: "文件名称不能为空",
            trigger: ["change", "blur"],
          },
        ],
        fileNo: [
          {
            required: true,
            message: "文件编号不能为空",
            trigger: ["change", "blur"],
          },
        ],
        applyChangeDept: [
          {
            required: true,
            message: "申请更改部门不能为空",
            trigger: ["change", "blur"],
          },
        ],
        changeReason: [
          {
            required: true,
            message: "更改原因不能为空",
            trigger: ["change", "blur"],
          },
        ],
        applicant: [
          {
            required: true,
            message: "申请人不能为空",
            trigger: ["change", "blur"],
          },
        ],
        applicationDate: [
          {
            required: true,
            message: "申请日期不能为空",
            trigger: ["change", "blur"],
          },
        ],
        changeBrief: [
          {
            required: true,
            message: "变更内容简述不能为空",
            trigger: ["change", "blur"],
          },
        ],
        reviewDeptOpinion: [
          {
            required: true,
            message: "审核部门意见不能为空",
            trigger: ["change", "blur"],
          },
        ],
        reviewer: [
          {
            required: true,
            message: "审核人不能为空",
            trigger: ["change", "blur"],
          },
        ],
        reviewDate: [
          {
            required: true,
            message: "审核日期不能为空",
            trigger: ["change", "blur"],
          },
        ],
        approvalDeptOpinion: [
          {
            required: true,
            message: "批准部门意见不能为空",
            trigger: ["change", "blur"],
          },
        ],
        approver: [
          {
            required: true,
            message: "批准人不能为空",
            trigger: ["change", "blur"],
          },
        ],
        approveDate: [
          {
            required: true,
            message: "批准时间不能为空",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  async created() {
    let params = {
      pageNum: 1,
      pageSize: 10,
    };
    let waterList = await getChangeList(params);
    this.waterList = waterList;
  },
  methods: {
    airDialogClose() {
      this.$refs.airFormRef.resetFields();
    },
    productFormSubmit() {
      this.$refs.airFormRef.validate(async (valid) => {
        if (valid) {
          if (this.handleType === "add") {
            console.log(this.airForm, "this.airForm");
            await addChangeList(this.airForm);
          } else if (this.handleType === "update") {
            let params = this.airForm;
            await updateChangeDetail(params);
          }
          this.waterList = await getChangeList(this.searchForm);
          this.airDialogVisible = false;
        }
      });
    },
    productFormSubmit2() {
      this.$refs.productFormRef.validate(async (valid) => {
        this.airDetailForm.airId = this.airForm.id;
        if (valid) {
          if (this.handleDetailType === "add") {
            let data = await addChangeList(this.airDetailForm);
            let list = this.airForm.airDetailList;
            list.push(data);
            this.airForm.airDetailList = list;
          } else if (this.handleDetailType === "update") {
            let list = this.airForm.airDetailList;
            console.log(this.airDetailForm, "this.airDetailForm");
            delete this.airDetailForm.index;
            delete this.airDetailForm.inputType;
            list.splice(this.airDetailForm.index, 1, this.airDetailForm);
            this.airForm.airDetailList = list;
          }
          this.innerVisible = false;
        }
      });
    },
    handleAdd() {
      this.airFormTitle = "新增文件信息";
      this.handleType = "add";
      this.airDialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteChangeList(row.id);
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--;
        }
        this.waterList = await getChangeList(this.searchForm);
      });
    },
    handleUpdate(index, row) {
      this.airFormTitle = "文件申请更改表";
      this.handleType = "update";
      getChangeDetail(row.id).then((res) => {
        this.airForm = { ...res };
        // this.runningTime = [res.startTime || "", res.endTime || ""];
        this.airDialogVisible = true;
      });
    },
    handleInfo(index, row) {
      this.airFormTitle = "文件申请更改表";
      this.handleType = "detail";
      getChangeDetail(row.id).then((res) => {
        this.airForm = { ...res };
        console.log({ ...res });
        // this.runningTime = [res.startupTime || "", res.offTime || ""];
        this.airDialogVisible = true;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      getChangeList(this.searchForm).then((res) => {
        console.log(res, "res");
        this.waterList = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      getChangeList(this.searchForm).then((res) => {
        this.waterList = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      getChangeList(this.searchForm).then((res) => {
        this.waterList = res;
      });
    },
  },

  watch: {
    runningTime(val, oldVal) {
      console.log(val, "val");
      this.airForm.entryExitDate = val.join("-");
    },
    airDialogVisible(val) {
      if (!val) {
        this.airForm = {};
        this.runningTime = [];
      }
    },
  },
};
</script>

<style>
#product section {
  margin: 0px 0px 20px 20px;
}
</style>
